/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiCheckLine, RiFileTextLine, RiDownloadLine, RiThumbUpLine, RiErrorWarningLine, RiTimeLine, RiQuestionLine, RiTeamLine } from "react-icons/ri"

const FeedbackTemplatesPage = () => {
  return (
    <Layout>
      <SEO 
        title="Employee Feedback Templates | Performance Management Tools"
        description="Download professional feedback templates for effective employee performance conversations. Includes templates for positive feedback, constructive criticism, peer reviews, and 360-degree assessments."
        keywords={[
          "employee feedback templates",
          "performance review templates",
          "constructive feedback examples",
          "positive feedback templates",
          "peer review templates",
          "360 degree feedback forms",
          "manager feedback examples",
          "effective feedback techniques",
          "performance conversation templates",
          "employee assessment templates"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Employee Feedback Templates
            </h1>
            
            <p sx={{
              fontSize: '1.1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              Transform your performance conversations with professionally designed feedback templates.
              Deliver clear, actionable, and effective feedback for every workplace scenario.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}>
              <a href="#download" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                }
              }}>
                Download Templates <RiDownloadLine />
              </a>
              
              <a href="#examples" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                border: '2px solid',
                borderColor: 'primary',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                }
              }}>
                Browse Examples <RiFileTextLine />
              </a>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div sx={{
        bg: 'white',
        py: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '2fr 1fr'],
          gap: 5
        }}>
          {/* Left Column - Main Content */}
          <div>
            {/* Introduction to Feedback Templates */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                The Power of Structured Feedback
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 3
              }}>
                Effective feedback is the cornerstone of high-performing teams and continuous improvement in the workplace.
                However, delivering feedback that is both impactful and well-received can be challenging.
                Our professionally designed templates help managers and team members provide feedback that is:
              </p>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 3,
                mb: 4
              }}>
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiCheckLine sx={{ color: 'primary' }} />
                    Specific & Actionable
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Focus on concrete behaviors and outcomes rather than vague generalizations, providing clear direction for improvement.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiThumbUpLine sx={{ color: 'primary' }} />
                    Balanced & Fair
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Address both strengths and development areas to create a complete picture of performance and potential.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiTimeLine sx={{ color: 'primary' }} />
                    Timely & Relevant
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Deliver feedback promptly when it can have the greatest impact on performance and development.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiErrorWarningLine sx={{ color: 'primary' }} />
                    Constructive & Respectful
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5
                  }}>
                    Frame feedback in a way that maintains dignity and encourages growth rather than demotivating recipients.
                  </p>
                </div>
              </div>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 0
              }}>
                Our feedback templates are designed by HR experts and organizational psychologists to help you navigate difficult conversations
                and strengthen your feedback culture. Each template provides a framework that can be customized to your specific needs and organizational context.
              </p>
            </div>
          
            {/* Template Examples */}
            <div id="examples" sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                Feedback Template Examples
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                Browse our collection of professionally designed feedback templates for different workplace scenarios. 
                Each template can be customized to fit your organization's culture and specific feedback needs.
              </p>
              
              {/* Positive Performance Feedback Template */}
              <div sx={{
                bg: 'white',
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
                mb: 4,
                overflow: 'hidden'
              }}>
                <div sx={{
                  borderBottom: '1px solid',
                  borderColor: 'rgba(0,0,0,0.06)',
                  p: 3,
                  bg: 'backgroundMuted'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    m: 0
                  }}>
                    <RiThumbUpLine sx={{ color: 'primary' }} />
                    Positive Performance Feedback Template
                  </h3>
                </div>
                
                <div sx={{ p: 3 }}>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 3
                  }}>
                    This template helps managers and peers recognize and reinforce excellent work, 
                    providing specific praise that highlights behaviors you want to see repeated. 
                    Effective positive feedback boosts morale, increases engagement, and strengthens desired behaviors.
                  </p>
                  
                  <div sx={{
                    bg: 'backgroundMuted',
                    p: 3,
                    borderRadius: '6px',
                    fontSize: '0.9rem',
                    fontStyle: 'italic',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 3
                  }}>
                    <strong>Example:</strong> "I want to recognize your excellent work on the [specific project]. 
                    Specifically, your [concrete action/behavior] demonstrated [specific skill/quality] and resulted in 
                    [measurable positive outcome]. This contributed to [broader impact] for the team/organization. 
                    This kind of performance exemplifies our value of [relevant company value]."
                  </div>
                  
                  <Link to="/contact" sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 2,
                    color: 'primary',
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}>
                    View Full Template <RiArrowRightSLine />
                  </Link>
                </div>
              </div>
              
              {/* Constructive Improvement Feedback Template */}
              <div sx={{
                bg: 'white',
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
                mb: 4,
                overflow: 'hidden'
              }}>
                <div sx={{
                  borderBottom: '1px solid',
                  borderColor: 'rgba(0,0,0,0.06)',
                  p: 3,
                  bg: 'backgroundMuted'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    m: 0
                  }}>
                    <RiErrorWarningLine sx={{ color: 'primary' }} />
                    Constructive Improvement Feedback Template
                  </h3>
                </div>
                
                <div sx={{ p: 3 }}>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 3
                  }}>
                    Address performance gaps or developmental areas with feedback that is clear, specific, 
                    and focused on improvement. This template helps deliver constructive criticism in a way 
                    that's respectful and growth-oriented rather than demotivating.
                  </p>
                  
                  <div sx={{
                    bg: 'backgroundMuted',
                    p: 3,
                    borderRadius: '6px',
                    fontSize: '0.9rem',
                    fontStyle: 'italic',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 3
                  }}>
                    <strong>Example:</strong> "I'd like to discuss [specific situation or behavior] that I observed during [specific time/project]. 
                    When [describe what happened objectively], it resulted in [impact on team/project/client]. 
                    In the future, I'd recommend [specific alternative approach] which would help to [expected positive outcome]. 
                    What support do you need to implement this change?"
                  </div>
                  
                  <Link to="/contact" sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 2,
                    color: 'primary',
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}>
                    View Full Template <RiArrowRightSLine />
                  </Link>
                </div>
              </div>
              
              {/* Peer Review Feedback Template */}
              <div sx={{
                bg: 'white',
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
                mb: 4,
                overflow: 'hidden'
              }}>
                <div sx={{
                  borderBottom: '1px solid',
                  borderColor: 'rgba(0,0,0,0.06)',
                  p: 3,
                  bg: 'backgroundMuted'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    m: 0
                  }}>
                    <RiTeamLine sx={{ color: 'primary' }} />
                    Peer Review Feedback Template
                  </h3>
                </div>
                
                <div sx={{ p: 3 }}>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 3
                  }}>
                    Structured peer feedback fosters team collaboration and helps employees gain insights from those they work with most closely.
                    This template guides team members in providing helpful, balanced feedback to colleagues in a way that strengthens working relationships.
                  </p>
                  
                  <div sx={{
                    bg: 'backgroundMuted',
                    p: 3,
                    borderRadius: '6px',
                    fontSize: '0.9rem',
                    fontStyle: 'italic',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 3
                  }}>
                    <strong>Example:</strong> "When working with you on [specific project/ongoing work], I've observed that you excel at [specific strength] 
                    which helps the team by [concrete positive impact]. One area where I think you could be even more effective is [specific area for development]. 
                    For example, during [specific situation], if you had [alternative approach], it might have [potential positive outcome]. 
                    I appreciate how you consistently [positive behavioral trait]."
                  </div>
                  
                  <Link to="/contact" sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 2,
                    color: 'primary',
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}>
                    View Full Template <RiArrowRightSLine />
                  </Link>
                </div>
              </div>
              
              {/* 360-Degree Feedback Template */}
              <div sx={{
                bg: 'white',
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
                mb: 0,
                overflow: 'hidden'
              }}>
                <div sx={{
                  borderBottom: '1px solid',
                  borderColor: 'rgba(0,0,0,0.06)',
                  p: 3,
                  bg: 'backgroundMuted'
                }}>
                  <h3 sx={{
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    color: 'text',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    m: 0
                  }}>
                    <RiQuestionLine sx={{ color: 'primary' }} />
                    360-Degree Feedback Template
                  </h3>
                </div>
                
                <div sx={{ p: 3 }}>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 3
                  }}>
                    Comprehensive 360-degree feedback gathers input from managers, peers, direct reports, and sometimes clients or stakeholders.
                    This holistic template helps collect well-rounded perspectives on performance, leadership qualities, and development areas.
                  </p>
                  
                  <div sx={{
                    bg: 'backgroundMuted',
                    p: 3,
                    borderRadius: '6px',
                    fontSize: '0.9rem',
                    fontStyle: 'italic',
                    color: 'text',
                    lineHeight: 1.6,
                    mb: 3
                  }}>
                    <strong>Categories covered include:</strong>
                    <ul sx={{ pl: 3, mb: 0 }}>
                      <li>Communication effectiveness (with examples)</li>
                      <li>Collaboration and teamwork behaviors</li>
                      <li>Problem-solving approach and decision quality</li>
                      <li>Leadership abilities (for supervisory roles)</li>
                      <li>Technical skills and knowledge application</li>
                      <li>Primary strengths and development opportunities</li>
                    </ul>
                  </div>
                  
                  <Link to="/contact" sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 2,
                    color: 'primary',
                    fontSize: '0.95rem',
                    fontWeight: 600,
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}>
                    View Full Template <RiArrowRightSLine />
                  </Link>
                </div>
              </div>
              
            </div>
          
            {/* How to Use Templates */}
            <div sx={{ 
              mb: 4,
              pb: 4,
              borderBottom: '1px solid',
              borderColor: 'rgba(0,0,0,0.06)'
            }}>
              <h2 sx={{
                fontSize: '1.75rem',
                fontWeight: 700,
                color: 'text',
                mb: 3
              }}>
                How to Use These Templates Effectively
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6,
                mb: 4
              }}>
                Feedback is most effective when delivered thoughtfully and at the right time. Follow these steps to get the most out of our templates:
              </p>
              
              <div sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
                gap: 4,
                mb: 0
              }}>
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiCheckLine sx={{ color: 'primary' }} />
                    Choose the Right Template
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5,
                    mb: 0
                  }}>
                    Select the template that best fits your feedback goals. Consider the relationship context, 
                    purpose of the feedback, and what you hope to achieve. Each template is designed for different scenarios.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiCheckLine sx={{ color: 'primary' }} />
                    Gather Specific Examples
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5,
                    mb: 0
                  }}>
                    Before providing feedback, collect concrete examples of behaviors or outcomes to reference. 
                    Feedback based on specific instances is more credible and actionable than general impressions.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiCheckLine sx={{ color: 'primary' }} />
                    Customize the Language
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5,
                    mb: 0
                  }}>
                    Adapt the template to your organization's culture and language. Use terminology familiar to your team 
                    and align the feedback with your company values and performance expectations.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px'
                }}>
                  <h3 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiCheckLine sx={{ color: 'primary' }} />
                    Choose the Right Time and Place
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5,
                    mb: 0
                  }}>
                    Deliver feedback in a private, distraction-free environment. For constructive feedback, 
                    consider the recipient's schedule and emotional state to ensure they can receive it effectively.
                  </p>
                </div>
                
                <div sx={{
                  bg: 'backgroundMuted',
                  p: 3,
                  borderRadius: '8px',
                  gridColumn: ['auto', 'auto', '1 / -1']
                }}>
                  <h3 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <RiCheckLine sx={{ color: 'primary' }} />
                    Follow Up
                  </h3>
                  <p sx={{
                    fontSize: '0.95rem',
                    color: 'text',
                    lineHeight: 1.5,
                    mb: 0
                  }}>
                    Schedule follow-up conversations to discuss progress, answer questions, and provide additional support. 
                    Effective feedback is an ongoing dialogue, not a one-time event.
                  </p>
                </div>
              </div>
            </div>
            
            {/* Benefits section will be added in the next edit */}
          
          </div>
          
          {/* Right Column - Sidebar */}
          <div>
            {/* Download Templates Section */}
            <div id="download" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
              mb: 4
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 3,
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiDownloadLine sx={{ color: 'primary' }} />
                Download Templates
              </h3>
              
              <p sx={{
                fontSize: '0.95rem',
                color: 'text',
                lineHeight: 1.5,
                mb: 3
              }}>
                Get our professionally designed feedback templates in multiple formats. Customize them for your organization.
              </p>
              
              <div sx={{
                display: 'grid',
                gap: 2,
                mb: 0
              }}>
                <Link to="/contact" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'backgroundMuted',
                  color: 'text',
                  p: 2,
                  borderRadius: '6px',
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.04)',
                    transform: 'translateY(-2px)'
                  }
                }}>
                  <RiDownloadLine sx={{ color: 'primary' }} />
                  <div>
                    <strong>Word Templates</strong>
                    <span sx={{ display: 'block', fontSize: '0.8rem', opacity: 0.8 }}>Editable .docx format</span>
                  </div>
                </Link>
                
                <Link to="/contact" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'backgroundMuted',
                  color: 'text',
                  p: 2,
                  borderRadius: '6px',
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.04)',
                    transform: 'translateY(-2px)'
                  }
                }}>
                  <RiDownloadLine sx={{ color: 'primary' }} />
                  <div>
                    <strong>PDF Templates</strong>
                    <span sx={{ display: 'block', fontSize: '0.8rem', opacity: 0.8 }}>Print-ready versions</span>
                  </div>
                </Link>
                
                <Link to="/contact" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'backgroundMuted',
                  color: 'text',
                  p: 2,
                  borderRadius: '6px',
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, background-color 0.2s ease',
                  '&:hover': {
                    bg: 'rgba(0,0,0,0.04)',
                    transform: 'translateY(-2px)'
                  }
                }}>
                  <RiDownloadLine sx={{ color: 'primary' }} />
                  <div>
                    <strong>Google Forms</strong>
                    <span sx={{ display: 'block', fontSize: '0.8rem', opacity: 0.8 }}>Digital collection templates</span>
                  </div>
                </Link>
              </div>
            </div>
            
            {/* Quick Tips Section */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
              mb: 4
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 3
              }}>
                Quick Feedback Tips
              </h3>
              
              <div sx={{
                display: 'grid',
                gap: 3
              }}>
                <div>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Be Timely
                  </h4>
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    lineHeight: 1.5,
                    mb: 0
                  }}>
                    The most effective feedback is delivered close to the event or behavior being discussed, while details are fresh.
                  </p>
                </div>
                
                <div>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Focus on Behavior
                  </h4>
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    lineHeight: 1.5,
                    mb: 0
                  }}>
                    Address specific actions and behaviors rather than personality traits or assumptions about intentions.
                  </p>
                </div>
                
                <div>
                  <h4 sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'text',
                    mb: 1
                  }}>
                    Avoid Feedback Sandwiches
                  </h4>
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    lineHeight: 1.5,
                    mb: 0
                  }}>
                    The practice of sandwiching criticism between compliments can diminish your message and create confusion.
                  </p>
                </div>
              </div>
            </div>
            
            {/* Related Tools Section */}
            <div sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.08)'
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                color: 'text',
                mb: 3
              }}>
                Related Tools
              </h3>
              
              <div sx={{
                display: 'grid',
                gap: 3
              }}>
                <Link to="/tools/goal-setting-template" sx={{
                  textDecoration: 'none',
                  color: 'text'
                }}>
                  <div sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'primary',
                    mb: 1
                  }}>
                    Goal Setting Template
                  </div>
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    lineHeight: 1.5,
                    mb: 0
                  }}>
                    Create SMART goals that align with feedback and development opportunities.
                  </p>
                </Link>
                
                <Link to="/tools/development-plan-template" sx={{
                  textDecoration: 'none',
                  color: 'text'
                }}>
                  <div sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    color: 'primary',
                    mb: 1
                  }}>
                    Development Plan Template
                  </div>
                  <p sx={{
                    fontSize: '0.9rem',
                    color: 'text',
                    lineHeight: 1.5,
                    mb: 0
                  }}>
                    Turn feedback into actionable development plans for career growth.
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* CTA Section */}
      <div sx={{
        bg: 'primary',
        color: 'white',
        py: [4, 5],
        textAlign: 'center'
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          px: 3
        }}>
          <h2 sx={{
            fontSize: ['1.75rem', '2rem'],
            fontWeight: 700,
            mb: 3
          }}>
            Ready to Transform Your Feedback Process?
          </h2>
          
          <p sx={{
            fontSize: '1.1rem',
            opacity: 0.9,
            mb: 4,
            lineHeight: 1.5
          }}>
            Download our professionally designed templates today and turn feedback into a strategic advantage for your organization. 
            Our experts are available to help you implement effective feedback processes that drive performance and engagement.
          </p>
          
          <div sx={{
            display: 'flex',
            gap: 3,
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}>
            <a href="#download" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'white',
              color: 'primary',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)'
              }
            }}>
              Get Free Templates <RiDownloadLine />
            </a>
            
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'transparent',
              color: 'white',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              border: '2px solid white',
              transition: 'transform 0.2s ease, background-color 0.2s ease',
              '&:hover': {
                bg: 'rgba(255,255,255,0.1)',
                transform: 'translateY(-2px)'
              }
            }}>
              Schedule a Consultation <RiArrowRightSLine />
            </Link>
          </div>
        </div>
      </div>
      
    </Layout>
  )
}

export default FeedbackTemplatesPage 